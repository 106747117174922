$bgColor: #4aa673;

.import-main-detail {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.service {
  .card {
    padding-left: 0;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    border-radius: 10px;
    min-height: 180px;
    cursor: pointer;
    margin: 5px;
    box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.68);
    -webkit-box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.68);
    -moz-box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.68);

    h4 {
      text-align: center;
    }

    img {
      width: 80px;
      height: 80px;
    }
  }
}

.account-Mobile {
  .header {
    min-height: 165px;
    padding: 15px;
    background-color: $bgColor;
    color: #fff;
    font-weight: 900;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    p {
      display: flex;
      align-items: center;
    }
  }

  .content {
    background-color: #fff;
    margin-top: -1rem;
    border-top-right-radius: 25px;
    border-top-left-radius: 25px;
    padding: 10px;
    min-height: 70px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: -1px 4px 5px 0px rgba(166, 166, 166, 0.68);
    -webkit-box-shadow: -1px 4px 5px 0px rgba(166, 166, 166, 0.68);
    -moz-box-shadow: -1px 4px 5px 0px rgba(166, 166, 166, 0.68);
  }

  .body {
    background-color: #eeeeee;
    min-height: 60vh;
    padding: 10px;
    margin-bottom: 50px;
    margin-top: 0.3rem;

    .card-list {
      border-radius: 10px;
      background-color: #fff;
      margin-bottom: 10px;
      cursor: pointer;

      .card-header {
        padding: 5px;
        background-color: #f5f5f5;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        p {
          font-size: 12px;
        }
      }
      .card-body {
        padding: 5px;
        .list {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          margin: 10px 0px 10px 0px;
           
          .image{
            width: 10%;
            display: flex;
            justify-content: center;
            align-items: center;
          }

          .card-content {
            width: 90%;
            display: flex;
            flex-direction: column;
            .list-title {
              display: flex;
              flex-direction: row;
              justify-content: space-between;
            }

            p {
              font-size: 12px;
            }
          }
        }

        hr {
          border: 1px solid lightgray;
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .account-Mobile {
    .header {
      min-height: 165px;
      padding: 15px;
      background-color: $bgColor;
      color: #fff;
      font-weight: 900;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
  
      p {
        display: flex;
        align-items: center;
      }
    }
  
    .content {
      background-color: #fff;
      margin-top: -1rem;
      border-top-right-radius: 25px;
      border-top-left-radius: 25px;
      padding: 10px;
      min-height: 70px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      box-shadow: -1px 4px 5px 0px rgba(166, 166, 166, 0.68);
      -webkit-box-shadow: -1px 4px 5px 0px rgba(166, 166, 166, 0.68);
      -moz-box-shadow: -1px 4px 5px 0px rgba(166, 166, 166, 0.68);
    }
  
    .body {
      background-color: #eeeeee;
      min-height: 60vh;
      padding: 10px;
      margin-bottom: 50px;
      margin-top: 0.3rem;
  
      .card-list {
        border-radius: 10px;
        background-color: #fff;
        margin-bottom: 10px;
  
        .card-header {
          padding: 5px;
          background-color: #f5f5f5;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          p {
            font-size: 12px;
          }
        }
        .card-body {
          padding: 5px;
          display: flex;
          flex-direction: column;
          .list {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            // margin: 10px 0px 10px 0px;
             
            .image{
              width: 20%;
              display: flex;
              justify-content: center;
              // align-items: center;
            }
  
            .card-content {
              width: 80%;
              display: flex;
              flex-direction: column;
              .list-title {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;

                h4{
                  font-size:14px;
                }
              }
  
              p {
                font-size: 12px;
              }
            }
          }
  
          hr {
            border: 1px solid lightgray;
            width: 100%;
          }
  
        }
      }
    }
  }

}


.bottomNavbar {
  box-shadow: 1px -5px 5px 0px rgba(224, 224, 224, 0.68);
  -webkit-box-shadow: 1px -5px 5px 0px rgba(224, 224, 224, 0.68);
  -moz-box-shadow: 1px -5px 5px 0px rgba(224, 224, 224, 0.68);
}

.account-mobile-view {
  .card-account {
    display: flex;
    flex-direction: column;
    padding: 10px;
    border-radius: 10px;
    margin-bottom: 10px;
    box-shadow: -1px 4px 5px 0px rgba(166, 166, 166, 0.68);
    -webkit-box-shadow: -1px 4px 5px 0px rgba(166, 166, 166, 0.68);
    -moz-box-shadow: -1px 4px 5px 0px rgba(166, 166, 166, 0.68);

    .header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 5px;
    }

    .body {
      padding: 5px;
    }

    .footer {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 5px;

      .manage {
        display: flex;
        flex-direction: row;
      }
    }
  }
}

.typeAccountTab {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  .left {
    cursor: pointer;
    width: 50%;
    text-align: center;
    padding: 10px;
    border-radius: 20px;
  }
  .rigth {
    cursor: pointer;
    width: 50%;
    text-align: center;
    padding: 10px;
    border-radius: 20px;
  }
}


.account-mobile-detail{
  padding: 10px;
  background-color: #eeeeee;
  min-height: 90vh;


  .detail-body{
    background-color: #fff;
    padding: 10px;

    .image{
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .content{
      display: flex;
      flex-direction: row;
      justify-content:space-between;
      padding:10px 0px 10px 0px;
    }

    .manage{
      margin-top: 2rem;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    hr{
      border: 0.1rem solid #eee;
    }


  }

}