@font-face {
  font-family: "NotoSansLao" !important;
  src: url("./fonts/NotoSansLao-Regular.ttf") format("truetype");
}

@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+Lao:wght@300&display=swap');


* {
  font-family: "NotoSansLao" !important;

}

*,
body,
.Dialogbox,
label,
div {
  margin: 0;
  font-family: "NotoSansLao" !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  padding: 0;
  margin: 0;
}


.login-body{
  background: red;
}